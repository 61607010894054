<template>
    <div class="viewContainer" >
        <!-- 内容 -->
        <div class="topTitle">
            样品索取
        </div>

        <hr  class="hr">

        <div class="content">
            <div class="email">
                <div class="title">
                    <img src="@/assets/image/services/mail.png" alt="">
                    <div class="text">邮件</div>
                    <div class="greyLine"></div>
                </div>
                <div class="tips">在填写时，请正确并详细的填写您的信息，如无公司名称，则请填写学校名称或城市名称+您的姓名，以便我们更好的为您提供相应的销售服务及技术支持，谢谢合作。</div>
                <div class="formPart">
                    <el-form ref="form" :model="form" :rules="rules" label-width="140px">
                        <el-form-item label="您的工作单位：">
                            <el-radio-group v-model="identity" @change="identityChange">
                                <el-radio :label="1">客户</el-radio>
                                <el-radio :label="2">高校</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="公司名称：" prop="companyName">
                            <el-input v-model="form.companyName" placeholder="公司名称"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人：" prop="contact">
                            <el-input v-model="form.contact" placeholder="联系人"></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式：" prop="phone">
                            <el-input v-model="form.phone" placeholder="联系方式"></el-input>
                        </el-form-item>
                        <el-form-item label="地址：" prop="address">
                            <el-input v-model="form.address" placeholder="地址"></el-input>
                        </el-form-item>
                        <el-form-item label="Email：" prop="email">
                            <el-input v-model="form.email" placeholder="Email"></el-input>
                        </el-form-item>
                        <!-- <hr  class="hr"> -->
                        <div class="productClass">
                            <div v-for="(item,index) in productList" :key="index">
                                <el-form-item :label="'产品型号'+(index+1)+'：'">
                                    <el-input v-model="item.product" :placeholder="'产品型号'+(index+1)"></el-input>
                                </el-form-item>
                                <el-form-item :label="'数量'+(index+1)+'：'">
                                    <el-input v-model="item.num" :placeholder="'数量'+(index+1)"></el-input>
                                </el-form-item>
                                <el-form-item :label="'预计年用量'+(index+1)+'：'">
                                    <el-input v-model="item.annualConsumption" :placeholder="'预计年用量'+(index+1)"></el-input>
                                </el-form-item>
                            </div>
                            <el-button class="addBtn" size="small" type="primary" @click="addProduct" ><i class="el-icon-plus"></i> 增加采购型号</el-button>
                        </div>
                        <!-- <hr  class="hr"> -->
                        <el-form-item label="应用方案" prop="application">
                            <el-input type="textarea" :autosize="{ minRows: 4, }" v-model="form.application" placeholder="详细真实的应用方案描述，有利于回访和提高申请成功率"></el-input>
                        </el-form-item>
                        <div class="btnClass">
                           <el-button :loading="loading" type="primary" @click="handleSubmit" style="margin-right:30px">发送邮件</el-button>
                           <el-button @click="handleReset" style="color: #fff;background-color: #122b40;border-color: #122b40;">重置</el-button>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
        <el-dialog
         title="提示"
         :visible.sync="dialogVisible"
         width="400px"
        >
            <div style="width:100%;font-size:20px; text-align:center;"><i style="color:green;" class="el-icon-circle-check"></i> 样品已申请！</div>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { requestSample, } from '@/services/serviceSupport.js'
export default {
  name: "saleSupport",
  components:{
  },
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        loading:false,
        identity:1,
        dialogVisible:false,
        form: {
        },
        rules: {
          companyName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' }
          ],
          contact: [
            { required: true, message: '请输入联系人', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入联系方式', trigger: 'blur' }
          ],
          address: [
            { required: true, message: '请输入地址', trigger: 'blur' }
          ],
          email: [
            { required: true, message: '请输入Email', trigger: 'blur' }
          ],
          application: [
            { required: true, message: '请输入应用方案', trigger: 'blur' }
          ],
        },
        productList:[],
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 768) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    this.handleReset()
  },
  activated(){
    this.handleReset()
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    //工作单位改变
    identityChange(val){
        this.identity = val
        if(val==2){
            window.open('https://www.wch.cn/h/education/request_sample/#/')
        }
    },
    //增加采购型号
    addProduct(){
        this.productList.push({
            product:'',
            num:'',
            annualConsumption:'',
        })
    },
    //发送邮件
    handleSubmit(){
        this.$refs.form.validate((valid) => {
          if (valid) {
            let params = {...this.form}
            params.productList = this.productList
            this.loading = true;
            requestSample(params).then(res => {
                if(res.data.code===0 ){
                    this.dialogVisible = true ;
                    this.handleReset()
                }else{
                    this.$message.error(res.data.message);
                }
                this.loading = false;
            });
          } else {
            console.log('error submit!!');
          }
        });
    },
    //重置
    handleReset(){
        this.identity = 1
        this.$refs.form.resetFields();
        this.form = {}
        this.productList = [
            {
                product:'',
                num:'',
                annualConsumption:'',
            }
        ]

    },
  }
};
</script>
<style scoped lang="less">
 .viewContainer{
    width: 100%;
    text-align: left;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;

    .topTitle{
        font-size: 22px;
        color: #002c85;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .hr{
        border-top: 1px solid #cbd0d4;
        margin: 25px 0px;
    }
    .content{
        
        .email{
            .formPart{
                width: 65%;
                margin: 0 auto;
                font-size: 14px;
                color: #030303;
                /deep/.el-form-item__label{
                    color: #030303;
                    font-weight: 600;
                    letter-spacing: 2px;
                }
                .blueText{
                    font-size: 18px;
                    color: #1ea9fb;
                    margin-left: 20px;
                    margin-bottom: 20px;
                    font-weight: bolder;
                }
                .btnClass{
                    display: flex;
                    justify-content: center;
                }
                .addBtn{
                    margin-left: 140px;
                    margin-bottom: 20px;
                }
                .productClass{
                    border-top: 1px dashed #ccc;
                    border-bottom: 1px dashed #ccc;
                    padding: 20px 0px 0px;
                    margin-bottom: 20px;
                }
            }
        }
        
        .title{
            display: flex;
            align-items: center;
            .text{
                font-size: 26px;
                float: left;
                margin-left: 20px;
            }
            .greyLine{
                margin-left: 20px;
                height: 10px;
                float: left;
                border-bottom: 1px solid #cbd0d4;
                flex: 1;
            }
        }
        .tips{
            margin-top: 20px;
            padding-left: 30px;
            font-size: 14px;
            color: #AFAFAF;
            padding-right: 50px;
            margin-bottom: 20px;
        }
    }
 }
@media screen and (max-width: 768px) {
    .viewContainer .content .email .formPart{
        width: 100%;
    }
}
</style>